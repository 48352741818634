import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
export const CurrencyInput = ({ label, data, onInputChange, maskOptions, placeholder, required, isDisabled, onMouseLeave, accessibilityLabel, }) => {
    const defaultMaskOptions = Object.assign({ prefix: '', suffix: '', includeThousandsSeparator: true, thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.', decimalLimit: 2, integerLimit: 15, allowNegative: false, allowLeadingZeroes: false }, maskOptions);
    const currencyMask = createNumberMask(Object.assign({}, defaultMaskOptions));
    // Documentation on how to create mask function:
    // https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#mask
    const customMaskFunction = (rawValue) => {
        let sanitizedValue = rawValue;
        // If we don't allow decimals, let's drop any decimal values
        // if a user pastes decimal values
        if (!defaultMaskOptions.allowDecimal) {
            const decimalIndex = rawValue.indexOf('.');
            if (decimalIndex > -1) {
                sanitizedValue = rawValue.slice(0, decimalIndex);
            }
        }
        return currencyMask(sanitizedValue);
    };
    return (_jsxs(FormControl, { children: [label ? (_jsxs(FormLabel, Object.assign({ display: 'flex' }, { children: [label, " ", required && _jsx("span", Object.assign({ className: 'required' }, { children: " *" }))] }))) : null, _jsxs(InputGroup, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: 'none' }, { children: "$" })), _jsx(Input, { "aria-label": `${accessibilityLabel}-currency-input`, pl: '24px', as: MaskedInput, mask: customMaskFunction, placeholder: placeholder ? placeholder : label, onChange: onInputChange, onMouseLeave: onMouseLeave, value: data, isDisabled: isDisabled })] })] }));
};
