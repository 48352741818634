var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { usePlannerPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { useScenarioPlan } from '../../contexts/Plans/PlanHook';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { useScenario } from '../../contexts/Scenarios/ScenarioHook';
import { PlanContainer } from './PlanContainer';
import { ScenarioInstructions } from './ScenarioInstructions';
import { ScenarioListDropdown } from './ScenarioListDropdown';
import { ScenarioModal } from './ScenarioModal';
import { ScenarioPlanButtons } from './ScenarioPlanButtons';
import { ScenarioPlanViolations } from './ScenarioPlanViolations';
import WidgetContainer from './WidgetContainer';
const ScenarioPage = () => {
    const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
    const { organizationDetails: orgCurrent } = useOrganization({
        organizationIdentity: orgIdentity,
    });
    const [zoomYear, setZoomYear] = useState();
    const [lockingScenario, setLockingScenario] = useState(false);
    const [years, setYears] = useState([]);
    const { projectList } = useProjectsContext();
    const { canEdit, canManageScenario, canExportProjects, canAddProject, canAddScenario, } = usePlannerPermissions(projectList);
    const handleScenarioUpsert = (newScenario) => {
        fetchScenarioPlan(newScenario.id);
    };
    const { scenario, scenarioList, fetchScenario, deleteScenario, upsertScenario, refreshScenarioAndScenarioList, createTaxImpact, } = useScenario(orgIdentity, handleScenarioUpsert);
    const { orgFundingTypes, orgEscalationFactor } = usePlannerSettingsContext();
    const { plan, scheduledProjects, unscheduledProjects, schedulePlan, addProjectToScenario, fetchScenarioPlan, lockUnlockScenario, removeProjectFromScenario, relationshipViolations, fetchTaxImpactCSV, isTaxImpactDownloading, } = useScenarioPlan(projectList, refreshScenarioAndScenarioList);
    const { triggerProjectsRefetch } = useProjectsContext();
    const location = useLocation();
    const scenarioAddDisclosure = useDisclosure();
    const navigate = useNavigate();
    useEffect(() => {
        setZoomYear(undefined);
    }, [scenario]);
    useEffect(() => {
        if (location.state && location.state.showModal) {
            scenarioAddDisclosure.onOpen();
            // clear state once used to avoid state mutation issues on reload
            window.history.replaceState({}, document.title);
            location.state.showModal = false;
        }
    });
    useEffect(() => {
        const yearsTemp = [];
        if (scenario) {
            for (let i = scenario.startYear; i < scenario.startYear + scenario.planDuration; i++) {
                yearsTemp.push(i);
            }
        }
        setYears(yearsTemp);
    }, [scenario]);
    const updatePlan = (scenarioId, project, fundingTypeId, year) => {
        if (scenario) {
            schedulePlan(scenarioId, project, fundingTypeId, year).then(() => {
                if (fundingTypeId) {
                    //  trigger refresh only on funding type being changed
                    triggerProjectsRefetch();
                }
            });
        }
    };
    const removeProject = (project) => {
        if (scenario) {
            removeProjectFromScenario(scenario.id, project);
        }
    };
    const onChangeScenario = (scenarioIdentity) => {
        fetchScenario(scenarioIdentity);
        fetchScenarioPlan(scenarioIdentity);
    };
    const onProjectUpdate = () => __awaiter(void 0, void 0, void 0, function* () {
        if (scenario) {
            fetchScenarioPlan(scenario.id);
        }
    });
    const editScenarioProps = {
        orgFundingTypes: orgFundingTypes,
        scenario: scenario,
        upsertScenario: upsertScenario,
        scenarioList: scenarioList,
    };
    const toggleLockScenario = (isLocking) => {
        setLockingScenario(true);
        if (scenario) {
            lockUnlockScenario(scenario.id, isLocking, scenario.name).then(() => {
                triggerProjectsRefetch();
                setLockingScenario(false);
            });
        }
    };
    const addProjects = (scenarioId, projects, year) => {
        addProjectToScenario(scenarioId, projects, year);
    };
    const fetchCSVForTaxImpact = () => __awaiter(void 0, void 0, void 0, function* () {
        if (scenario && orgCurrent) {
            return yield fetchTaxImpactCSV(scenario.id, `${orgCurrent.name}_${scenario.name}.csv`, 'text/csv');
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageLayout, Object.assign({ title: 'myScenarios', canAddProject: canAddProject, canAddScenario: canAddScenario, onAddProject: (orgIdentity) => navigate(AppRoutes.myProjects(orgIdentity), {
                    state: { showModal: true },
                }), onAddScenario: scenarioAddDisclosure.onOpen, canEdit: canEdit }, { children: _jsxs(VStack, Object.assign({ px: '40px', spacing: 6, width: '100%' }, { children: [_jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsxs(HStack, { children: [scenarioList.length > 0 && (_jsx(ScenarioListDropdown, { scenarioIdentity: scenario === null || scenario === void 0 ? void 0 : scenario.id, scenarioList: scenarioList, onChangeScenario: onChangeScenario })), scenario && plan && canEdit && (_jsx(ScenarioPlanViolations, { hasRelatedProjects: plan.hasRelatedProjects, relationshipViolations: relationshipViolations }))] }), scenario && (_jsx(ScenarioPlanButtons, { scenarioIsLocked: scenario.isLocked, selectedScenarioId: scenario.id, toggleLockScenario: toggleLockScenario, editScenario: editScenarioProps, deleteScenario: deleteScenario, taxImpact: scenario.taxImpact, createTaxImpact: createTaxImpact, fetchTaxImpactCSV: fetchCSVForTaxImpact, isTaxImpactDownloading: isTaxImpactDownloading, canEdit: canEdit, canManageScenario: canManageScenario, canExportProjects: canExportProjects }))] })), !scenario || orgIdentity === undefined ? (_jsx(ScenarioInstructions, { canAddProject: canAddProject, hasNoProjects: projectList.length === 0 ? true : false, hasNoScenarios: scenarioList.length === 0 ? true : false, canEdit: canEdit })) : (_jsxs(VStack, Object.assign({ w: 'full', pb: 6, spacing: 6 }, { children: [_jsx(Box // TODO: try to add in layerStyle={layerStyles.widgetsPageContainer} later - Matt B
                                , Object.assign({ borderRadius: '2px', border: `1px solid ${appColors.PRIM_LIGHT_GREY}`, w: '100%', h: '35vh', display: 'block', p: '10px' }, { children: plan && (_jsx(WidgetContainer, { scenarioId: scenario.id, fundingTypesList: orgFundingTypes, planData: plan, budgetType: scenario.budgetType, planningFactor: scenario.planningFactors, years: years })) })), _jsx(Box // TODO: try to add in layerStyle={layerStyles.widgetsPageContainer} later - Matt B
                                , Object.assign({ borderRadius: '2px', border: `1px solid ${appColors.PRIM_LIGHT_GREY}`, w: '100%', h: '80vh', display: 'block', p: '10px' }, { children: _jsx(PlanContainer, { fundingTypes: orgFundingTypes, scenario: scenario, scheduledProjects: scheduledProjects, unscheduledProjects: unscheduledProjects, setZoomYear: setZoomYear, addProjects: addProjects, removeProject: removeProject, updatePlan: updatePlan, zoomYear: zoomYear, escalationFactor: orgEscalationFactor, fetchScenarioPlan: onProjectUpdate, years: years, canEdit: canEdit, orgIdentity: orgIdentity }) }))] }))), scenarioAddDisclosure.isOpen && (_jsx(ScenarioModal, { disclosure: scenarioAddDisclosure, fundingTypes: orgFundingTypes, isEditModal: false, scenario: undefined, upsertScenario: upsertScenario, scenarioList: scenarioList }))] })) })), _jsx(LoadingWithControl, { isLoading: lockingScenario })] }));
};
export default ScenarioPage;
